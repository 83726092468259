@font-face {
    font-family: 'Krungthai Fast';
    src: url('/assets/fonts/KrungthaiFast-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Krungthai Fast';
    src: url('/assets/fonts/KrungthaiFast-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Krungthai Fast';
    src: url('/assets/fonts/KrungthaiFast-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Krungthai Fast';
    src: url('/assets/fonts/KrungthaiFast-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}